import React, { Fragment, FunctionComponent, useState, ChangeEvent, useEffect } from 'react';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/styles';
import { Theme as AugmentedTheme, Typography, Button, TextField, Snackbar, SnackbarContent } from '@material-ui/core';
import { SessionContext } from '../components/Session/Context';
import { withAuthorization } from '../components/Session/withAuthorization';
import { withEmailVerification } from '../components/Session/withEmailVerification';
import { AuthUser } from '../types/modelsFirebase';
import { Card, CardContent } from '@material-ui/core';
import { withFirebase } from '../components/Firebase/withFirebase';
import { FirebaseClient } from '../components/Firebase/Client';
import { Localization } from '../localization/Localization';
import { LocalizationKey } from '../localization/LocalizationKey';

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingTop: theme.remSpacing(12),
    textAlign: 'center'
  },
  card: {
    width: 360
  },
  accountTitle: {
    color: theme.colors.oxfordBlue06,
    paddingBottom: theme.remSpacing(4)
  },
  changePasswordTitle: {
    paddingTop: theme.spacing(10)
  },
  errorMessage: {
    color: theme.colors.red
  },
  confirmationMessage: {
    color: theme.colors.oxfordBlue
  },
  input: {
    margin: theme.spacing(1),
    width: 250
  },
  actionButton: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    height: '43px',
    boxShadow: 'unset',
    backgroundColor: 'transparent',
    textTransform: 'none'
  },
  actionButtonDisabled: {
    color: theme.colors.oxfordBlue02
  },
  actionButtonLabel: {
    color: theme.colors.black,
    textDecoration: 'underline'
  },
  actionButtonLabelDisabled: {
    color: theme.colors.oxfordBlue02
  },
  forceChangePasswordMessageWrapper: {
    backgroundColor: theme.colors.seaGreen
  },
  forceChangePasswordMessage: {
    color: theme.colors.oxfordBlue
  }
}));

interface Props {
  firebase: FirebaseClient;
}

const AccountPageBase: FunctionComponent<Props> = ({ firebase }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [passwordChangeErrorReason, setPasswordChangeErrorReason] = useState<any | null>(null);
  const [passwordHasBeenChanged, setPasswordHasBeenChanged] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordRepeated, setNewPasswordRepeated] = useState('');
  const [canChangePassword, setCanChangePassword] = useState(false);
  const [currentPasswordInvalidReason, setCurrentPasswordInvalidReason] = useState<string | null>(null);
  const [newPasswordInvalidReason, setNewPasswordInvalidReason] = useState<string | null>(null);

  const styles = useStyles();

  useEffect(() => {
    if (currentPassword.length > 0 && newPassword.length > 0 && newPasswordRepeated.length > 0) {
      setCanChangePassword(true);
    } else {
      setCanChangePassword(false);
    }
  }, [currentPassword, newPassword, newPasswordRepeated]);

  const setHasChangedPasswordClaim = () => {
    if (firebase.auth.currentUser) {
      const addUserHasChangedPasswordClaimFunction = firebase.addUserHasChangedPasswordClaimFunction();
      addUserHasChangedPasswordClaimFunction({ userUid: firebase.auth.currentUser.uid })
        .then(() => {
          console.log('"hasChangedPassword" claim has been set');
        })
        .catch((reason) => {
          console.error('Could not set custom claim "hasChangedPassword", reason: ', reason);
        });
    }
  };

  const clearPasswords = () => {
    setCurrentPassword('');
    setNewPassword('');
    setNewPasswordRepeated('');
  };

  const onSubmit = () => {
    if (newPassword != newPasswordRepeated) {
      setNewPasswordInvalidReason('password is not the same');
      return;
    } else {
      setNewPasswordInvalidReason(null);
    }

    const user = firebase.auth.currentUser;
    if (!user) {
      return;
    }

    const credential = firebase.firebaseNamespace.auth.EmailAuthProvider.credential(user.email, currentPassword);

    user
      .reauthenticateWithCredential(credential)
      .then(() => {
        setCurrentPasswordInvalidReason(null);

        user
          .updatePassword(newPassword)
          .then(() => {
            setPasswordHasBeenChanged(true);
            setPasswordChangeErrorReason(null);
            clearPasswords();
            setHasChangedPasswordClaim();
          })
          .catch((reason) => {
            setPasswordChangeErrorReason(reason.message);
          });
      })
      .catch((reason) => {
        setCurrentPasswordInvalidReason(reason.message);
      });
  };

  const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.currentTarget.name == 'currentPassword') {
      setCurrentPassword(event.currentTarget.value);
      setCurrentPasswordInvalidReason(null);
    } else if (event.currentTarget.name == 'newPassword') {
      setNewPassword(event.currentTarget.value);
    } else if (event.currentTarget.name == 'newPasswordRepeated') {
      setNewPasswordRepeated(event.currentTarget.value);
      setNewPasswordInvalidReason(null);
    }
  };

  const actionButtonClasses = [styles.actionButton];
  if (!canChangePassword) {
    actionButtonClasses.push(styles.actionButtonDisabled);
  }

  const actionButtonLabelClasses = [styles.actionButtonLabel];
  if (!canChangePassword) {
    actionButtonLabelClasses.push(styles.actionButtonLabelDisabled);
  }

  return (
    <Fragment>
      <SessionContext.Consumer>
        {(authUser) =>
          authUser &&
          authUser.user && (
            <div className={styles.container}>
              <Card className={styles.card}>
                <CardContent>
                  <Typography variant="h1" className={styles.accountTitle} gutterBottom>
                    {Localization.getInst().localizedString(LocalizationKey.myAccountTitle)}
                  </Typography>
                  {authUser.user.email && (
                    <Fragment>
                      <Typography variant="h2">{authUser.user.email}</Typography>

                      <Typography className={styles.changePasswordTitle} variant="h4">
                        {Localization.getInst().localizedString(LocalizationKey.changePasswordTitle)}
                      </Typography>
                      <div>
                        <TextField
                          error={currentPasswordInvalidReason === null ? false : true}
                          className={styles.input}
                          label={Localization.getInst().localizedString(LocalizationKey.currentPassword)}
                          name="currentPassword"
                          placeholder={Localization.getInst().localizedString(LocalizationKey.currentPassword)}
                          type="password"
                          helperText={currentPasswordInvalidReason}
                          onChange={onChange}
                          value={currentPassword}
                        />
                      </div>
                      <div>
                        <TextField
                          className={styles.input}
                          label={Localization.getInst().localizedString(LocalizationKey.newPassword)}
                          name="newPassword"
                          placeholder={Localization.getInst().localizedString(LocalizationKey.newPassword)}
                          type="password"
                          onChange={onChange}
                          value={newPassword}
                        />
                      </div>
                      <div>
                        <TextField
                          error={newPasswordInvalidReason === null ? false : true}
                          className={styles.input}
                          label={Localization.getInst().localizedString(LocalizationKey.repeatNewPassword)}
                          name="newPasswordRepeated"
                          placeholder={Localization.getInst().localizedString(LocalizationKey.repeatNewPassword)}
                          type="password"
                          helperText={newPasswordInvalidReason}
                          onChange={onChange}
                          value={newPasswordRepeated}
                        />
                      </div>

                      <Button
                        className={actionButtonClasses.join(' ')}
                        disabled={!canChangePassword}
                        onClick={onSubmit}
                      >
                        <Typography variant="h3" className={actionButtonLabelClasses.join(' ')}>
                          {Localization.getInst().localizedString(LocalizationKey.changePasswordButtonText)}
                        </Typography>
                      </Button>

                      {passwordChangeErrorReason && <p>{passwordChangeErrorReason.message}</p>}
                    </Fragment>
                  )}
                  {passwordChangeErrorReason && (
                    <Typography className={styles.errorMessage} variant="h5">
                      {passwordChangeErrorReason}
                    </Typography>
                  )}
                  {passwordHasBeenChanged && (
                    <Typography className={styles.confirmationMessage} variant="h5">
                      {Localization.getInst().localizedString(LocalizationKey.passwordHasBeenChanged)}
                    </Typography>
                  )}
                </CardContent>
              </Card>
              <Snackbar
                open={!authUser.hasChangedPassword}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
              >
                <SnackbarContent
                  classes={{ root: styles.forceChangePasswordMessageWrapper }}
                  message={
                    <Typography className={styles.forceChangePasswordMessage} variant="h5">
                      {Localization.getInst().localizedString(LocalizationKey.forceChangePasswordMessage)}
                    </Typography>
                  }
                />
              </Snackbar>
            </div>
          )
        }
      </SessionContext.Consumer>
    </Fragment>
  );
};

const NavBarBaseFirebse1 = withFirebase(AccountPageBase);

const condition = (authUser: AuthUser) => !!authUser.user;

const AccountPage = compose<Pick<Props, never>, {}>(
  withEmailVerification,
  withAuthorization(condition),
  withFirebase
)(NavBarBaseFirebse1);

export default () => <AccountPage />;
